import React, { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login from './pages/Login';
import store, { persistor } from './redux/store';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Register from './pages/Register';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Role from './pages/Role';
import User from './pages/User';
import Vehicle from './pages/Vehicle';
import Requisition from './pages/Requisition';
import Requisitions from './pages/Requisitions';
import PrivateRoute from './components/PrivateRoute';
import NotFound from './pages/404';
import Unauthorized from './pages/Unauthorized';
import Pump from './pages/Pump/Create';
import { Toaster } from 'react-hot-toast';
import FuelPurchase from './pages/FuelPurchase';
import LocationCreate from './pages/Location/Create';
import { generateToken, messaging } from './firebase';
import { onMessage } from 'firebase/messaging';
import { State } from './types/state';
import { updateFCMToken } from './api';
import { toastMessage } from './utlls/toaster';
import PumpList from './pages/Pump/List';
import DepartmentCreate from './pages/Department/Create';
const queryClient = new QueryClient();

function QueryProvider ({ children } : { children: React.ReactNode }) {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

const router = createBrowserRouter(
  [
    {
      path: '/',
      errorElement: <NotFound />,
      children: [
        { 
          element: <PrivateRoute allowedRoles={['request', 'view', 'approve']} />,
          children: [
            {
              path: '/',
              children: [
                {
                  path: '/', element: <Requisitions />,
                },
                {
                  path: 'requisitions',
                  children: [
                    {
                      path: 'create',
                      element: <Requisition />,
                    },
                    {
                      path: 'list',
                      element: <Requisitions />,
                    }
                  ]
                },
              ]
            },
            {
              path: 'users',
              children: [
                { path: 'create', element: <User />}
              ]
            },
            {
              path: 'pump',
              children: [
                { path: 'fuel-purchase', element: <FuelPurchase /> },
              ],
            },
          ]
        },
        { path: 'unathorised', element: <Unauthorized /> },
        {
          element: <PrivateRoute allowedRoles={['create', 'update', 'delete', 'view']} />,
          children: [
            {
              path: 'vehicles',
              children: [
                { path: 'create', element: <Vehicle /> },
              ],
            },
            {
              path: 'roles',
              children: [
                { path: 'create', element: <Role /> },
              ],
            },
            {
              path: 'pump',
              children: [
                { path: 'create', element: <Pump /> },
              ],
            },
            {
              path: 'location',
              children: [
                { path: 'create', element: <LocationCreate /> },
              ],
            },
            {
              path: 'department',
              children: [
                { path: 'create', element: <DepartmentCreate /> },
              ],
            },
          ],
        },
        { 
          element: <PrivateRoute allowedRoles={['edit']} />,
          children: [
            {
              path: 'pump',
              children: [
                { path: 'list', element: <PumpList /> },
              ],
            },
          ]
        },
        {
          path: 'auth',
          children: [
            { path: 'sign-in', element: <Login /> },
            { path: 'register', element: <Register /> },
          ]
        }
      ],
    },
  ],
);

function FireBaseMessaging({ children } : { children: React.ReactNode }) {
  const user = useSelector((state: State) => state.auth.user);

  useEffect(() => {
    const subscribeNotifications = async () => {
      const token = await generateToken();
  
      if (token !== 'denied' || 'default') updateFCMToken(user.token, token);
    }

    subscribeNotifications();

    onMessage(messaging, (payload) => {
      toastMessage(payload?.data?.body || '');
    });
  }, [user]);

  return <>{children}</>
}

function App() {
  return (
    <>
      <QueryProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <FireBaseMessaging>
              <RouterProvider router={router} />
            </FireBaseMessaging>
          </PersistGate>
        </Provider>
      </QueryProvider>
      <Toaster
        toastOptions={{
          success: {
            style: {
              borderRadius: '8px',
              fontSize: '14px'
            }
          },
          error: {
            style: {
              borderRadius: '8px',
              fontSize: '14px'
            }
          }
        }}/>
    </>
  );
}

export default App;
