import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { approveRequisition, disburseRequisition, downloadReciept, getRequisitons, rejectRequisition } from '../api';
import Table from '../components/Table';
import Button, { IconButton } from '../components/Button';
import MainLayout from '../layout/MainLayout';
import { toastMessage } from '../utlls/toaster';
import Badge from '../components/Badge';
import { CrudModal } from '../components/Modal';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';

const Requisitions = () => {
  const state = useSelector((state: any) => state);
  const [docId, setDocId] = useState<string>('');
  const [reason, setReason] = useState<string>('');
  const queryClient = useQueryClient();

  const { data: requisitions } = useQuery({
    queryKey: ['requisitions'],
    queryFn: () => getRequisitons(state.auth.user.token),
  });

  const approve = async (id: string) => {
    try {
      const data = await approveRequisition(state.auth.user.token, id);

      toastMessage(data);
    } catch (error) {
      console.log(error)
    } finally {
      queryClient.invalidateQueries({ queryKey: ['requisitions'] })
    }
  }

  const disburse = async (id: string) => {
    try {
      const data = await disburseRequisition(state.auth.user.token, id);

      toastMessage(data);
    } catch (error: any) {
      if (error.response) {
        return toastMessage(error.response.data.err, 'error');
      }
      toastMessage(error.message, 'error');
    } finally {
      queryClient.invalidateQueries({ queryKey: ['requisitions'] })
    }
  }

  const onReject = (id: string) => setDocId(id);
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => setReason(e.target.value);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!reason) return toastMessage('You cannot send an empty reason for rejecting the requisition.', 'error');
    try {
      const data = await rejectRequisition(state.auth.user.token, docId, reason);

      toastMessage(data);
      setReason('');
    } catch (error) {
      console.log(error);
    } finally {
      queryClient.invalidateQueries({ queryKey: ['requisitions'] })
    }
  }

  const onDownload = async (id: string) => {
    try {
      const data = await downloadReciept(state.auth.user.token, id);      

      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf'
        })
      );

      // uses the download attribute on a temporary anchor to trigger the browser
      // download behavior. if you need wider compatibility, you can replace this
      // part with a library such as filesaver.js
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", id + '.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

    } catch (error: any) {
      if (error.response) {
        toastMessage(error.response.data.err, 'error');
      }

      toastMessage(error.message, 'error');
    }
  }

  const tHeadData: {
    title: string
    maxWidth: string
    align?: 'center' | 'left' | 'right'
  }[] =
    [
      {
        title: 'Driver Name',
        maxWidth: 'auto'
      },
      {
        title: 'Created By',
        maxWidth: 'auto'
      },
      {
        title: 'Vehicle Name',
        maxWidth: '200px'
      },
      {
        title: 'Number Plate',
        maxWidth: '150px'
      },
      {
        title: 'Tank Capacity',
        maxWidth: '100px',
        align: 'center'
      },
      {
        title: 'Litres Requested',
        maxWidth: '120px',
        align: 'center'
      },
      {
        title: 'Status',
        maxWidth: 'auto',
        align: 'center'
      },
      {
        title: 'Actions',
        maxWidth: 'auto',
        align: 'center'
      }
    ]

  const TBody = ({
    data
  }: {
    data: any
  }) => {
    const role = state.auth.user.role.name.toLowerCase();

    return (
      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <th className="px-6 py-4 text-xs text-gray-900 whitespace-nowrap dark:text-white">
          {data?.driverName}
        </th>
        <th scope="row" className="px-6 py-4 text-xs font-medium text-gray-900 whitespace-nowrap dark:text-white">
          {data?.user?.name}
        </th>
        <td className="px-6 py-4 text-xs">
          {data?.vehicle?.name}
        </td>
        <td className="px-6 py-4 text-xs text-center">
          {data?.vehicle?.numberPlate}
        </td>
        <td className="px-6 py-4 text-xs text-center">
          {data?.vehicle?.fuelTankCapacity}
        </td>
        <td className="px-6 py-4 text-xs text-center">
          {data?.litres}
        </td>
        <td className="px-6 py-4 text-xs text-center">
          {data?.isRejected && <Badge colors='bg-red-100 text-red-800' text='Rejected' />}
          {data?.isDisbursed && <Badge colors='bg-green-100 text-green-800' text="Issued" />}
          {
            (!data?.isRejected && !data?.isDisbursed) &&
            <>
              {
                data?.supervisor && data?.hod ?
                  <Badge colors='bg-purple-100 text-purple-800' text='Pending Issuance' /> :
                  <Badge colors='bg-purple-100 text-purple-800' text='Pending Approval' />
              }
            </>
          }
        </td>
        <td className="px-6 py-4 text-xs flex gap-x-2">
          {
            data?.isRejected ?
              <Badge text='No Actions Available' colors='bg-yellow-100 text-yellow-800' /> :
              <>
                {
                  role === 'hod' &&
                    <Button
                      type="button"
                      size="small"
                      label="Approve"
                      onClick={() => approve(data?.id)}
                      disabled={!data?.supervisor || data?.hod} />
                }
                {
                  role === 'supervisor' &&
                    <Button
                      type="button"
                      size="small"
                      label="Approve"
                      onClick={() => approve(data?.id)}
                      disabled={data?.supervisor} /> 
                }
                {
                  role === 'pump' &&
                    <Button
                      type="button"
                      size="small"
                      label="Disburse"
                      onClick={() => disburse(data?.id)}
                      disabled={(!data?.hod && !data?.supervisor) || (data?.isDisbursed)} />
                }
                {
                  (role === 'hod' || role === 'supervisor') &&
                  <Button
                    type="button"
                    size="small"
                    colors='bg-red-600 hover:bg-red-400'
                    label="Reject"
                    onClick={() => onReject(data?.id)}
                    disabled={(role === 'supervisor' && data?.supervisor) || ((role === 'hod' && data?.hod) && data?.supervisor)} />
                }
                
                {
                  data?.hod && data?.supervisor &&
                  <IconButton size='small' onClick={() => onDownload(data?.id)} disabled={!data?.isDisbursed}>
                    <ArrowDownTrayIcon width={18} height={18} />
                  </IconButton>
                }
              </>
          }
        </td>
      </tr>
    )
  }

  const TBodyData = () => (
    <>
      {
        requisitions &&
        requisitions?.requisitions?.map((item: any) => <TBody key={item?.id} data={item} />)
      }
    </>
  )

  return (
    <MainLayout>
      <CrudModal
        isOpen={Boolean(docId)}
        onClose={() => setDocId('')}
      >
        <form onSubmit={onSubmit} className="p-4 md:p-5">
          <div className="grid gap-4 mb-4 grid-cols-2">
            <div className="col-span-2">
              <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter Reason</label>
              <input onChange={onInputChange} type="text" name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Type Reason" />
            </div>
          </div>
          <Button type='submit' label='Submit' colors='bg-blue-600 hover:bg-blue-400' />
        </form>
      </CrudModal>
      <Table tHeadData={tHeadData} tBodyData={<TBodyData />} />
    </MainLayout>
  )
}

export default Requisitions