import React from 'react';
// import Logo from 'assets/imgs/transparent-logo.png';
import { Formik, Form, FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';
import Input from '../components/Input';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { create } from '../redux/requisition/requisitionSlice';
import Button from '../components/Button';
import { useQuery } from '@tanstack/react-query';
import { getDepartments, getPumps, getVehicles } from '../api';
import Select from '../components/Select';
import { RequisitionValues } from '../types/state';
import MainLayout from '../layout/MainLayout';
import { capitalize } from '../utlls/common';

const Requisition = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const state = useSelector((state: any) => state);

  const { data } = useQuery({
    queryKey: ['vehicles'],
    queryFn: () => getVehicles(state.auth.user.token)
  });

  const { data: pumps } = useQuery({
    queryFn: () => getPumps(state.auth.user.token),
    queryKey: ['pumps']
  });

  const { data: departments } = useQuery({
    queryFn: () => getDepartments(state.auth.user.token),
    queryKey: ['departments']
  });

  const validator = object({
    driverName: string()
      .required('Please enter the driver name.'),
    driverNumber: string()
      .required('Please enter the driver number.'),
    destination: string()
      .required('Please enter the destination.'),
    litres: string()
      .required('Please enter the amount of litres.'),
    vehicle: string()
      .required('Please select a vehicle.'),
    closingMileage: string()
      .required('Please enter the closing mileage'),
    pump: string()
      .required('Please select a pump.'),
    department: string()
      .required('Please select a department.')
  });

  const onSubmit =
    (values: RequisitionValues, actions: FormikHelpers<RequisitionValues>) =>
      dispatch(create(Object.assign(values, { token: state.auth.user.token })))
        .then((res) => res.type.includes('fulfilled') && actions.resetForm());

  const selectOptions = data ?
    data
      ?.map((vehicle: { numberPlate: string, id: string, fuelType: string }) =>
        ({ label: `${vehicle.numberPlate} (${capitalize(vehicle.fuelType)})`, value: vehicle.id })) : [];

  const findVehicle = (array: [], id: string): { id: string, numberPlate: string, fuelType: string, mileage: string } =>
    array.find((value: { id: string, numberPlate: string, fuelType: string }) => value.id === id) ?? ({ id: '', numberPlate: '', fuelType: '', mileage: '' });

  const findPump = (array: [], id: string): { id: string, name: string, fuelType: string } =>
    array?.find((pump: { id: string }) => pump?.id === id) ?? ({ id: '', name: '', fuelType: '' });

  const calcNetKms = (openingMileage: string, closingMileage: string) : string => {
    const opening = openingMileage ? Number(openingMileage) : 0;
    const closing = closingMileage ? Number(closingMileage) : 0;

    if (opening > closing) return '0';

    return `NET: ${closing - opening} Kms`;
  }

  const filteredPumps = pumps?.pumps?.filter((pump: { location: { id: number} }) => pump.location.id === state.auth.user.location.id);

  return (
    <MainLayout>
      <div className="flex min-h-full flex-col justify-center px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-lg">
          {/* <img className="mx-auto h-20 w-auto" src={Logo} alt="Your Company" /> */}
          <h1 className="mt-3 text-center text-2xl font-bold leading-9 tracking-tight text-dark-blue">Create Requisition</h1>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[700px]">
          <Formik
            initialValues={{
              driverName: '',
              driverNumber: '',
              vehicle: '',
              litres: '',
              destination: '',
              closingMileage: '',
              trackingMileage: '',
              pump: '',
              department: ''
            }}
            validationSchema={validator}
            onSubmit={onSubmit}
          >
            {
              ({
                values,
              }) => (
                <Form className="md:flex md:flex-wrap lg:flex lg:flex-wrap justify-between">

                  <Input name="driverName" label="Driver Name" type="text" isFlex />

                  <Input name="driverNumber" label="Driver Number" type="text" isFlex />

                  <div className="flex mt-2 sm:w-full md:w-[48%] lg:w-[48%] items-end justify-between">
                    <Select
                      value={values.vehicle ? { label: `${findVehicle(data, values.vehicle).numberPlate} (${capitalize(findVehicle(data, values.vehicle).fuelType)})`, value: values.vehicle } : null}
                      options={selectOptions}
                      name='vehicle'
                      label='Vehicle'
                      flexClasses="w-[68%]"
                      isFlex />
                    <span className="w-[28%] text-sm text-emerald-500">{values.closingMileage && calcNetKms(findVehicle(data, values.vehicle).mileage, values.closingMileage)}</span>
                  </div>


                  <Input name="closingMileage" label="Closing Mileage" type="text" isFlex />

                  <Select
                    value={values.department ? { label: values.department, value: values.department } : null}
                    options={departments?.map((department: { name: string }) => ({ label: department.name, value: department.name }))}
                    name='department'
                    label='Department'
                    isFlex />

                  <Input name="destination" label="Destination" type="text" isFlex />

                  <Select
                    name="pump"
                    options={filteredPumps?.map((pump: { id: string, name: string, fuelType: string }) => ({ value: pump.id, label: `${pump.name} (${capitalize(pump.fuelType)})` }))}
                    label="Select Pump"
                    value={values.pump ? { label: `${findPump(pumps?.pumps, values.pump).name} (${capitalize(findPump(pumps?.pumps, values.pump).fuelType)})`, value: findPump(pumps?.pumps, values.pump).id } : null}
                    isFlex />

                  <Input name="litres" label="Litres" type="text" isFlex />

                  <div className="w-full mt-5">
                    <Button type="submit" label="Create Requisition" disabled={state.requisition.isLoading} />
                  </div>
                </Form>
              )
            }
          </Formik>
        </div>
      </div>
    </MainLayout>
  );
};

export default Requisition;
